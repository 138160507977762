<template>
  <el-dialog :title="titleText" :visible.sync="requestDialog" width="980px" :modal="false" :close-on-click-modal="false"
    @close="closeDialog" custom-class="project_dialog">
    <section class="propose_project">
      <div class="px-3 course_box">
        <el-form label-position="top" label-width="80px" :model="formLabelAlign">
          <el-form-item class="star" label="Project name">
            <el-input v-model="formLabelAlign.project_name"></el-input>
          </el-form-item>
          <el-form-item class="star" label="Organization" style="margin-bottom:20px">
            <el-row :gutter="5">
              <OrganizationApply @getOrganizationParams="getOrganizationApply" :spanValue='6' />
            </el-row>
          </el-form-item>
          <el-form-item label="Contact person for the project">
            <el-input v-model="formLabelAlign.contact"></el-input>
          </el-form-item>
          <el-form-item label="Duration of the project">
            <el-input v-model="formLabelAlign.duration"></el-input>
          </el-form-item>
          <el-form-item class="star" label="Is it a RISE project?" style="margin-bottom:20px">
            <section>
              <el-radio v-model="formLabelAlign.is_has_project" :label="1">Yes</el-radio>
              <el-radio v-model="formLabelAlign.is_has_project" :label="0">No</el-radio>
            </section>
          </el-form-item>
          <el-form-item class="star" label="RISE Pillars category" v-if="formLabelAlign.is_has_project">
            <el-checkbox-group v-model="formLabelAlign.project_category" class="goal_multi">
              <el-checkbox :label="v.value" v-for="(v, i) in categoryList" :key="i">{{v.label}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item class="star" label="Areas of Opportunity category">
            <el-checkbox-group v-model="formLabelAlign.area_category" class="goal_multi">
              <el-checkbox :label="v.value" v-for="(v, i) in opportunityList" :key="i">{{v.label}}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item class="star" label="Year of the project">
            <el-date-picker style="width: 100%" clearable v-model="formLabelAlign.year" type="year" value-format="yyyy">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Project Manager name">
            <el-input v-model="formLabelAlign.project_manager_name"></el-input>
          </el-form-item>
          <el-form-item label="Contact email or phone number">
            <el-input v-model="formLabelAlign.whatsApp_number"></el-input>
          </el-form-item>
          <el-form-item label="Picture of the Project Manager">
            <div class="d-flex align-items-end">
              <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false"
                :http-request="params => {uploadIMG(params, 2)}" v-loading="loadingManage">
                <img v-if="formLabelAlign.project_manager_photo" :src="formLabelAlign.project_manager_photo" alt="" />
                <img v-else src="@/assets/images/settings/camera.png" alt="" />
              </el-upload>
              <span class="text12">(Aspect ratio: 1 to 1)</span>
            </div>
          </el-form-item>
          <el-form-item class="star" :label="summary_label">
            <section class="position-relative">
              <el-input type="textarea" :rows="3" resize="none" v-model="formLabelAlign.summary"
                @blur="val=>limitInput(val,summary_config,formLabelAlign.summary)"
                @keyup.32.native="e=>limitInput(e,summary_config,formLabelAlign.summary)"></el-input>
              <div class="position-absolute" style="line-height: 1.5;">
                <span v-show="summary_config.warning" style="color:#F56C6C;">Cannot exceed {{summary_config.limit}}
                  words</span>
              </div>
              <div class="position-absolute" style="right: 0;line-height: 1.5;" v-if="summary_config.limit">
                <span class="text12">
                  <span :style="{'color':summary_config.textNum>summary_config.limit?'#F56C6C':''}">
                    {{summary_config.textNum }}</span> / {{summary_config.limit}}
                </span>
              </div>
            </section>
          </el-form-item>
          <!-- <el-form-item :label="objective_label">
            <el-input type="textarea" :rows="3" v-model="formLabelAlign.objective"
              @blur="val=>limitInput(val,objective_config,formLabelAlign.objective)"></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="objective_config.warning" style="color:#F56C6C;">Cannot exceed {{objective_config.limit}}
                words</span>
            </div>
          </el-form-item>
          <el-form-item :label="achievement_label">
            <el-input type="textarea" :rows="3" v-model="formLabelAlign.achievement"
              @blur="val=>limitInput(val,achievement_config,formLabelAlign.achievement)"></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="achievement_config.warning" style="color:#F56C6C;">Cannot exceed
                {{achievement_config.limit}}
                words</span>
            </div>
          </el-form-item>
          <el-form-item :label="positive_impact_label">
            <el-input type="textarea" :rows="3" v-model="formLabelAlign.positive_impact"
              @blur="val=>limitInput(val,positive_impact_config,formLabelAlign.positive_impact)"></el-input>
            <div class="position-absolute" style="line-height: 1.5;">
              <span v-show="positive_impact_config.warning" style="color:#F56C6C;">Cannot exceed
                {{positive_impact_config.limit}} words</span>
            </div>
          </el-form-item> -->
          <el-form-item class="star"
            label="Upload two pictures. The main picture will be the cover photo of the project and the other will be seen inside of the project details."
            style="margin-top: 30px;">
            <p class="text12" style="margin:-14px 0 0 8px;color:#999">Maximum upload file size: 5MB</p>
            <section class="d-flex picture_flex" style="margin:-16px 0 0 8px">
              <div>
                <span class="text12">Main Picture to feature on the Project Gallery:</span>
                <section class="d-flex text_flex">
                  <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false"
                    :http-request="params => {uploadIMG(params, 3)}" v-loading="loadingMain">
                    <img v-if="formLabelAlign.main_picture" :src="formLabelAlign.main_picture" alt="" />
                    <img v-else src="@/assets/images/settings/camera.png" alt="" />
                  </el-upload>
                  <span class="text12">Aspect ratio: 2 to 1 (width to height)</span>
                </section>
              </div>
              <div style="margin-left: 100px;" class="picture_left">
                <span class="text12">Seen inside of the project details page:</span>
                <section class="d-flex text_flex">
                  <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false"
                    :http-request="params => {uploadIMG(params, 1)}" v-loading="loadingApply">
                    <img v-if="formLabelAlign.detail_picture" :src="formLabelAlign.detail_picture" alt="" />
                    <img v-else src="@/assets/images/settings/camera.png" alt="" />
                  </el-upload>
                  <span class="text12">Aspect ratio: 2 to 1 (width to height)</span>
                </section>
              </div>
            </section>
          </el-form-item>
          <!-- 新添加 -->
          <section v-if="is_extended">
            <el-form-item class="star mt-3" style="margin-bottom: 15px;"
              label="Primary Sustainable Development Goal (SDG) Addressed (Choose the main SDG the project impacts most, If it’s a RISE Project only SDGs 1, 3, 4, 8, 9, 11 and 12 should be available to select)">
              <el-radio-group v-model="formLabelAlign.primary_sdg" class="goal_select">
                <el-radio :label="v.value" v-for="(v, i) in SDGOptions" :key="i"
                  :disabled="(!!formLabelAlign.is_has_project)&&v.disabled">{{v.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="star"
              label="Secondary SDGs Addressed (Multi-Select – Choose additional SDGs impacted by the project’s activities) ">
              <el-checkbox-group v-model="formLabelAlign.secondary_sdg" class="goal_multi">
                <el-checkbox :label="v.value" v-for="(v, i) in SDGOptions" :key="i">{{v.label}}</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="star" :label='SDG_label'>
              <section class="position-relative">
                <el-input type="textarea" :rows="3" resize="none" v-model="formLabelAlign.project_contribute"
                  @blur="val=>limitInput(val,SDG_config,formLabelAlign.project_contribute)"
                  @keyup.32.native="e=>limitInput(e,SDG_config,formLabelAlign.project_contribute)"></el-input>
                <div class="position-absolute" style="line-height: 1.5;">
                  <span v-show="SDG_config.warning" style="color:#F56C6C;">Cannot exceed {{SDG_config.limit}}
                    words</span>
                </div>
                <div class="position-absolute" style="right: 0;line-height: 1.5;">
                  <span class="text12">
                    <span :style="{'color':SDG_config.textNum>SDG_config.limit?'#F56C6C':''}">
                      {{SDG_config.textNum }}</span> / {{SDG_config.limit}}
                  </span>
                </div>
              </section>
            </el-form-item>
            <el-form-item class="star mt-5"
              label="Number of Beneficiaries (Numeric Entry – Individuals impacted directly)">
              <el-input v-model="formLabelAlign.number_beneficiaries" @input="(value) => {limitNumber(value,1)}">
              </el-input>
            </el-form-item>
            <el-form-item class="star mt-3 demo_item" label="Demographics of Beneficiaries (Select all that apply)">
              <section class="ml-3">
                <div>
                  <p class="star mb-2" style="color:#333;">Age Group (Multi-Select Checkboxes)</p>
                  <el-checkbox-group v-model="formLabelAlign.age_group" class="goal_multi">
                    <el-checkbox :label="v.value" v-for="(v, i) in ageOptions" :key="i">{{v.label}}</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div class="mt-1">
                  <p class="star mb-2" style="color:#333;">Gender (Multi-Select Checkboxes)</p>
                  <el-checkbox-group v-model="formLabelAlign.gender">
                    <el-checkbox label="1">Male</el-checkbox>
                    <el-checkbox label="2">Female</el-checkbox>
                    <el-checkbox label="3">Other</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div>
                  <p class="star mb-2" style="color:#333;">Location (Multi-Select Checkboxes)</p>
                  <el-checkbox-group v-model="formLabelAlign.location">
                    <el-checkbox label="1">Urban</el-checkbox>
                    <el-checkbox label="2">Rural</el-checkbox>
                    <el-checkbox label="3">Suburban</el-checkbox>
                  </el-checkbox-group>
                </div>
                <div>
                  <p class="mb-2" style="color:#333;">Socio-Economic Background (Multi-Select
                    Checkboxes
                    – Optional but useful for impact analysis)</p>
                  <el-checkbox-group v-model="formLabelAlign.socio_economic_background" class="goal_multi">
                    <el-checkbox :label="v.value" v-for="(v, i) in SociolOption" :key="i">{{v.label}}</el-checkbox>
                  </el-checkbox-group>
                </div>
              </section>
            </el-form-item>
            <el-form-item class="star" label="Number of Volunteers Engaged (Numeric Entry – JCI members & external)">
              <el-input v-model="formLabelAlign.number_volunteers_engaged" @input="(value) => {limitNumber(value,2)}">
              </el-input>
            </el-form-item>
            <el-form-item class="star mt-3" label="Volunteer Activities Breakdown (Multi-Select)">
              <el-checkbox-group v-model="formLabelAlign.volunteer_activities_breakdown" class="goal_multi">
                <el-checkbox :label="v.value" v-for="(v, i) in VolunteerOptions" :key="i">{{v.label}}</el-checkbox>
                <div class="d-flex">
                  <el-checkbox label="11">other (please specify)</el-checkbox>
                  <el-input v-model="formLabelAlign.other_breakdown" class="specify_text"
                    v-if="formLabelAlign.volunteer_activities_breakdown.includes('11')"></el-input>
                </div>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="star"
              label="Total Hours Contributed by Volunteers (Numeric Entry – Sum of all volunteers' hours) ">
              <el-input v-model="formLabelAlign.total_hours_volunteers" @input="(value) => {limitNumber(value,3)}">
              </el-input>
            </el-form-item>
            <el-form-item class="mt-3" label="Funds Raised (if applicable) (Numeric Entry – Amount in USD) ">
              <div class="mt-1">
                <p class="mb-2 ml-3" style="height: 30px;color:#333;">Total Project Cost (USD)</p>
                <el-input v-model="formLabelAlign.total_project_cost" @input="(value) => {limitNumber(value,4)}">
                </el-input>
              </div>
              <div class="mt-1">
                <p class="mb-2 ml-3" style="height: 30px;color:#333;">Sponsorship Secured (USD)</p>
                <el-input v-model="formLabelAlign.sponsorship_secured" @input="(value) => {limitNumber(value,5)}">
                </el-input>
              </div>
            </el-form-item>
            <el-form-item class="star mt-3"
              label="Partnerships Involved (Multi-Select)">
              <el-checkbox-group v-model="formLabelAlign.partnerships_involved" class="goal_multi">
                <el-checkbox label="1">Academia</el-checkbox>
                <el-checkbox label="2">Private companies</el-checkbox>
                <el-checkbox label="3">Government bodies</el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="star" label="Have you used the JCI Action Framework to implement this project?">
              <section>
                <el-radio v-model="formLabelAlign.is_used_jci_framework" :label="1">Yes</el-radio>
                <el-radio v-model="formLabelAlign.is_used_jci_framework" :label="0">No</el-radio>
              </section>
            </el-form-item>
            <el-form-item label="Upload Photos/Videos of the Project (File Upload – Max 5 images, 1 video link)">
              <section class="d-flex flex-wrap">
                <div class="all_image d-flex flex-wrap" v-if="formLabelAlign.upload_photos_or_videos.length">
                  <div class="common_item" v-for="(v, i) in formLabelAlign.upload_photos_or_videos" :key="i">
                    <img v-if="v.type == 'image'" :src="v.url" alt="" class="main_img" />
                    <video v-else :src="v.url" controls></video>
                    <img class="close_img" src="@/assets/images/add_post_close.png" @click.stop="unshiftMedia(i)" />
                  </div>
                </div>
                <section class="d-flex text_flex upload_box mr-5"
                  v-if="formLabelAlign.upload_photos_or_videos.length<5">
                  <el-upload class="avatar-uploader" action="no" :http-request="params => {imgUpload(params, 1)}"
                    :show-file-list="false" multiple v-loading="loadingImg1" :before-upload="beforeUploadImage">
                    <img src="@/assets/images/settings/camera.png" alt="">
                  </el-upload>
                  <span class="text_upload">(Aspect ratio: 1 to 1)</span>
                </section>
                <section class="d-flex text_flex upload_box_file">
                  <div class="common_item" v-if="videoUrl">
                    <video :src="videoUrl" controls autoplay></video>
                    <img class="close_img" src="@/assets/images/add_post_close.png" @click.stop="videoUrl=''" />
                  </div>
                  <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false"
                    :http-request="params => {imgUpload(params, 2)}" v-loading="loadingImg2"
                    :before-upload="beforeUpload" v-else>
                    <img src="@/assets/images/settings/video.png" alt="" />
                  </el-upload>
                </section>
              </section>
            </el-form-item>
            <el-form-item
              label="Project Reports or Testimonials (File Upload – Reports, Impact Stories, Media Coverage, etc.) "
              style="margin-top: 30px;" class="file_form">
              <p class="text12" style="margin:-10px 0 0 0px;color:#999">Allowed file extensions: JPG, JPEG, PDF Maximum
                upload file size: 5 MB</p>
              <section class="d-flex text_flex">
                <el-upload class="avatar-uploader mr-3" action="no" :show-file-list="false"
                  :http-request="params => {uploadFileProject(params)}" v-loading="loadingFile">
                  <img src="@/assets/images/settings/file.png" alt="" />
                </el-upload>
                <div class="file_url" v-if="formLabelAlign.project_reports_or_testimonials">
                  <section class="mr-3 mb-3 d-flex align-content-center" v-if="fileType=='jpg'">
                    <img src="../../../assets/images/public/jpg_file.png" alt="" style="width: 28px;height: 28px;">
                    <span class="ml-1">{{fileName}}</span>
                    <div @click="showViewer=true">
                      <el-button class="ml-2" icon="el-icon-search" size="mini" circle type="primary"></el-button>
                    </div>
                    <div>
                      <el-button class="ml-2" type="danger" icon="el-icon-delete" circle size="mini"
                        @click="formLabelAlign.project_reports_or_testimonials=''"></el-button>
                    </div>
                    <el-image-viewer v-show="showViewer" :url-list="[formLabelAlign.project_reports_or_testimonials]"
                      :on-close="()=>{showViewer=false}">
                    </el-image-viewer>
                  </section>
                  <section class="mr-3 mb-3 d-flex align-content-center" v-if="fileType=='jpeg'">
                    <img src="../../../assets/images/public/jpeg_file.png" alt="" style="width: 28px;height: 28px;">
                    <span class="ml-1">{{fileName}}</span>
                    <div @click="showViewer=true">
                      <el-button class="ml-2" icon="el-icon-search" size="mini" circle type="primary"></el-button>
                    </div>
                    <div>
                      <el-button class="ml-2" type="danger" icon="el-icon-delete" circle size="mini"
                        @click="formLabelAlign.project_reports_or_testimonials=''"></el-button>
                    </div>
                    <el-image-viewer v-show="showViewer" :url-list="[formLabelAlign.project_reports_or_testimonials]"
                      :on-close="()=>{showViewer=false}">
                    </el-image-viewer>
                  </section>
                  <section class="mr-3 mb-3 d-flex align-content-center" v-if="fileType=='pdf'">
                    <img src="../../../assets/images/public/pdf_file.png" alt="" style="width: 28px;height: 28px;">
                    <span class="ml-1">{{fileName}}</span>
                    <div>
                      <el-button class="ml-2" icon="el-icon-search" size="mini" circle type="primary"
                        @click="openUrl(formLabelAlign.project_reports_or_testimonials)"></el-button>
                    </div>
                    <div>
                      <el-button class="ml-2" type="danger" icon="el-icon-delete" circle size="mini"
                        @click="formLabelAlign.project_reports_or_testimonials=''"></el-button>
                    </div>
                  </section>
                </div>
              </section>
            </el-form-item>
            <el-form-item class="mt-3"
              label="Links to Media Coverage / Website (URL Entry – Any online features about the project)">
              <el-input v-model="formLabelAlign.media_link"></el-input>
            </el-form-item>
            <el-form-item class="star mt-3" style="margin-bottom: 15px;"
              label="Is this project scalable or replicable in other JCI Local Organizations?">
              <el-radio-group v-model="formLabelAlign.is_other_jci">
                <el-radio :label="1">Yes</el-radio>
                <el-radio :label="0">No</el-radio>
                <el-radio :label="2">Unsure</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item class="star" :label="need_label" v-if="formLabelAlign.is_other_jci==1">
              <section class="position-relative">
                <el-input type="textarea" :rows="3" resize="none" v-model="formLabelAlign.yes_need_expand"
                  @blur="val=>limitInput(val,need_config,formLabelAlign.yes_need_expand)"
                  @keyup.32.native="e=>limitInput(e,need_config,formLabelAlign.yes_need_expand)"></el-input>
                <div class="position-absolute" style="line-height: 1.5;">
                  <span v-show="need_config.warning" style="color:#F56C6C;">Cannot exceed {{need_config.limit}}
                    words</span>
                </div>
                <div class="position-absolute" style="right: 0;line-height: 1.5;">
                  <span class="text12">
                    <span :style="{'color':need_config.textNum>need_config.limit?'#F56C6C':''}">
                      {{need_config.textNum }}</span> / {{need_config.limit}}
                  </span>
                </div>
              </section>
            </el-form-item>
            <el-form-item class="star mt-5" label="How will this project be sustained long-term?"
              v-if="formLabelAlign.is_other_jci==1">
              <el-radio-group v-model="formLabelAlign.how_long_term">
                <el-radio label="1">Community-led</el-radio>
                <el-radio label="2">JCI-led</el-radio>
                <el-radio label="3">Partnership-based</el-radio>
                <el-radio label="4">One-time initiative</el-radio>
              </el-radio-group>
            </el-form-item>
          </section>
        </el-form>
      </div>

      <div class="px-3 confirm_box">
        <el-checkbox v-model="checked">I confirm that all information provided is accurate and represents the project
          truthfully.</el-checkbox>
        <el-checkbox v-model="agree">I agree to allow JCI to feature this project in reports, marketing materials, and
          case studies.</el-checkbox>
      </div>

      <div class="p-3 d-flex justify-content-between next_apply_btn">
        <el-button type="warning" plain :disabled="summary_config.warning||SDG_config.warning||need_config.warning"
          @click="saveDraft" :loading="draftLoading">Save
          draft</el-button>
        <el-button type="primary" :loading="submitLoading"
          :disabled="!agree||!checked||summary_config.warning||SDG_config.warning||need_config.warning"
          @click="funSubmit">Submit
        </el-button>
      </div>
    </section>
  </el-dialog>
</template>
<script>
  import OrganizationApply from '@/components/ComComponents/OrganizationApply'
  import ElImageViewer from "element-ui/packages/image/src/image-viewer";

  export default {
    components: {
      OrganizationApply,
      ElImageViewer
    },

    props: {
      titleText: {
        type: String,
        default: 'Create a Project'
      }
    },

    watch: {
      'formLabelAlign.is_has_project': {
        deep: true,
        handler(newData) {
          this.formLabelAlign.primary_sdg = ''
        },
      }
    },

    data() {
      return {
        opportunityList: [
          { value: '1', label: 'Business and Entrepreneurship' },
          { value: '2', label: 'International Cooperation' },
          { value: '3', label: 'Community Impact' },
          { value: '4', label: 'Individual Development' }
        ],
        categoryList: [
          { value: '1', label: 'Preserving Mental Health' },
          { value: '2', label: 'Sustaining and Rebuilding Economies' },
          { value: '3', label: 'Workforce Motivation' }
        ],
        requestDialog: false,
        formLabelAlign: {
          project_name: '',
          area_id: '',
          area: '',
          national_id: '',
          national: '',
          local_id: '',
          local: '',
          organization_param: '',
          contact: '',
          duration: '',
          project_category: [],
          area_category: [],
          year: '',
          summary: '',
          objective: '',
          achievement: '',
          positive_impact: '',
          main_picture: '',
          detail_picture: '',
          project_manager_photo: '',
          whatsApp_number: '',
          project_manager_name: '',
          is_has_project: 1,

          // 新添加
          primary_sdg: '',
          secondary_sdg: [],
          project_contribute: '',
          number_beneficiaries: '',
          age_group: [],
          gender: [],
          location: [],
          socio_economic_background: [],
          number_volunteers_engaged: '',
          volunteer_activities_breakdown: [],
          other_breakdown: '',
          total_hours_volunteers: '',
          total_project_cost: '',
          sponsorship_secured: '',
          partnerships_involved: [],
          is_used_jci_framework: 1,
          is_other_jci: 1,
          upload_photos_or_videos: [],
          project_reports_or_testimonials: '',
          media_link: '',
          yes_need_expand: '',
          how_long_term: '',
        },
        loadingApply: false,
        loadingMain: false,
        loadingManage: false,
        checked: false,
        warning: false,
        summary_config: { limit: 0, warning: false, textNum: 0 },
        objective_config: { limit: 0, warning: false },
        achievement_config: { limit: 0, warning: false },
        positive_impact_config: { limit: 0, warning: false },
        summary_label: "Summary of the project",
        // summary_label: "1、Summary of The Project",
        objective_label: "What were the primary objectives of the project?",
        achievement_label: "Please list the key milestones or achievements accomplished during the project.",
        positive_impact_label: "Describe the positive impact the project had on the organization, its stakeholders, or the community.",

        SDGOptions: [
          { value: '1', label: 'SDG 1: No Poverty' },
          { value: '2', label: 'SDG 2: Zero Hunger', disabled: true },
          { value: '3', label: 'SDG 3: Good Health & Well-being' },
          { value: '4', label: 'SDG 4: Quality Education' },
          { value: '5', label: 'SDG 5: Gender Equality', disabled: true },
          { value: '6', label: 'SDG 6: Clean Water & Sanitation', disabled: true },
          { value: '7', label: 'SDG 7: Affordable & Clean Energy', disabled: true },
          { value: '8', label: 'SDG 8: Decent Work & Economic Growth' },
          { value: '9', label: 'SDG 9: Industry, Innovation & Infrastructure' },
          { value: '10', label: 'SDG 10: Reduced Inequalities', disabled: true },
          { value: '11', label: 'SDG 11: Sustainable Cities & Communities' },
          { value: '12', label: 'SDG 12: Responsible Consumption & Production' },
          { value: '13', label: 'SDG 13: Climate Action', disabled: true },
          { value: '14', label: 'SDG 14: Life Below Water', disabled: true },
          { value: '15', label: 'SDG 15: Life on Land', disabled: true },
          { value: '16', label: 'SDG 16: Peace, Justice & Strong Institutions', disabled: true },
          { value: '17', label: 'SDG 17: Partnerships for the Goals', disabled: true },
        ],
        VolunteerOptions: [
          { value: '1', label: 'Project Planning & Coordination' },
          { value: '2', label: 'Training & Capacity Building' },
          { value: '3', label: 'Event Logistics & Operations' },
          { value: '4', label: 'Fundraising & Sponsorship Outreach' },
          { value: '5', label: 'Mentorship & Coaching' },
          { value: '6', label: 'Awareness & Advocacy Campaigns' },
          { value: '7', label: 'Marketing & Communications (Social Media, PR, etc.)' },
          { value: '8', label: 'Data Collection & Impact Measurement' },
          { value: '9', label: 'Technology & Digital Support' },
          { value: '10', label: 'Partnership Engagement & Relationship Management' },
        ],
        ageOptions: [
          { value: '1', label: 'Children (0-12 years)' },
          { value: '2', label: 'Teenagers (13-17 years)' },
          { value: '3', label: 'Youth (18-25 years)' },
          { value: '4', label: 'Young Adults (26-35 years)' },
          { value: '5', label: 'Adults (36-55 years)' },
          { value: '6', label: 'Seniors (56+ years)' },
        ],
        SociolOption: [
          { value: '1', label: 'Low-income communities' },
          { value: '2', label: 'Middle-income communities' },
          { value: '3', label: 'High-income communities' },
          { value: '4', label: 'Vulnerable groups (e.g., refugees, people with disabilities, marginalized communities, etc.)' },
        ],

        SDG_config: { limit: '250', warning: false, textNum: 0 },
        need_config: { limit: '250', warning: false, textNum: 0 },
        SDG_label: 'Explain how your project contributes to the selected SDGs (Short Text Entry – Max 250 words)',
        need_label: 'If Yes, what resources or support would be needed to expand it? (Short Text Entry – Funding, training, partnerships. Max 250 words)',
        loadingImg1: false,
        loadingFile: false,
        loadingImg2: false,
        agree: false,
        videoUrl: '',
        is_extended: 1,
        fileName: '',
        fileType: '',
        showViewer: false,
        submitLoading: false,
        draftLoading: false
      }
    },

    methods: {
      openUrl(url) {
        window.open(url)
      },

      getOrganizationApply(data) {
        this.formLabelAlign.organization_param = JSON.stringify(data)
        let OrganData = JSON.parse(JSON.stringify(data))
        let { local, regional, national, area } = OrganData
        this.formLabelAlign.area_id = area.id || 0
        this.formLabelAlign.area = area.name || ''
        this.formLabelAlign.national_id = national.id || 0
        this.formLabelAlign.national = national.name || ''
        this.formLabelAlign.local_id = local.id || ''
        this.formLabelAlign.local = local.name || ''
      },

      async openDialog(id) {
        this.clearFormLabelAlign()
        this.getProjectConfig()
        this.checked = true
        this.agree = true
        this.id = id
        await this.$http.getProjectDetail({ id }).then((res) => {
          if (res.status == 200) {
            Object.keys(this.formLabelAlign).forEach(k => {
              if (res.data[k] && k != 'language') this.formLabelAlign[k] = res.data[k]
            })
            this.formLabelAlign.is_has_project = res.data.is_has_project

            if (res.data.organization_param) {
              setTimeout(() => { this.$bus.$emit('getBusParentDataApply', JSON.parse(res.data.organization_param)) }, 500)
            }
            this.is_extended = res.data.is_extended

            if (this.is_extended) {
              this.formLabelAlign.upload_photos_or_videos.forEach((item, index) => {
                if (item.type == 'video') {
                  this.videoUrl = item.url
                  this.formLabelAlign.upload_photos_or_videos.splice(index, 1)
                }
              })
              this.formLabelAlign.primary_sdg = String(res.data.primary_sdg)

              this.fileType = this.formLabelAlign.project_reports_or_testimonials
              let strIndex = this.fileType.lastIndexOf('.');
              if (strIndex === -1) { } else { this.fileType = this.fileType.substring(strIndex + 1) }

              this.fileName = this.formLabelAlign.project_reports_or_testimonials
              let nameIndex = this.fileName.lastIndexOf('/');
              if (nameIndex === -1) { } else { this.fileName = this.fileName.substring(nameIndex + 1) }
            }

          }
        })
        if (this.formLabelAlign.summary) this.limitInput('', this.summary_config, this.formLabelAlign.summary)
        if (this.formLabelAlign.project_contribute) this.limitInput('', this.SDG_config, this.formLabelAlign.project_contribute)
        if (this.formLabelAlign.yes_need_expand) this.limitInput('', this.need_config, this.formLabelAlign.yes_need_expand)
        this.requestDialog = true
      },

      closeDialog() {
        if (this.isDialogClose) return false
        this.$confirm(this.$t('Posts.488@close_the_pop_up_box'), 'Tips', {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.clearFormLabelAlign()
        }).catch(() => {
          this.requestDialog = true
        })
      },

      // 新增项目---存为草稿
      saveDraft() {
        this.addOrEditProject(0)
      },

      // 新增项目---发布
      funSubmit() {
        this.addOrEditProject(1)
      },

      // 项目配置
      async getProjectConfig(status) {
        let res = await this.$http.getProjectConfig({})
        if (res.status == 200) {
          this.summary_config.limit = res.data.summary_count
          this.SDG_config.limit = res.data.project_contribute_count
          this.need_config.limit = res.data.yes_need_expand_count
          if (res.data.summary_count) this.summary_label = `Summary of the project(Max ${res.data.summary_count} words – Overview of objectives, justification, and activities)`
          if (res.data.project_contribute_count) this.SDG_label = `Explain how your project contributes to the selected SDGs (Short Text Entry – Max ${res.data.project_contribute_count} words)`
          if (res.data.yes_need_expand_count) this.need_label = `If Yes, what resources or support would be needed to expand it? (Short Text Entry – Funding, training, partnerships. Max ${res.data.yes_need_expand_count} words)`
        }
      },

      // 新增/编辑项目
      async addOrEditProject(status) {
        if (status == 1) {
          this.submitLoading = true
        } else {
          this.draftLoading = true
        }
        if (this.videoUrl) {
          this.formLabelAlign.upload_photos_or_videos.push({
            type: "video",
            url: this.videoUrl
          })
        }
        // console.log(this.formLabelAlign);
        let res = await this.$http.addOrEditProject({
          id: this.id,
          is_extended: this.id ? this.is_extended : 1,
          status,
          ...this.formLabelAlign
        })
        if (res.status == 200) {
          this.isDialogClose = true
          this.requestDialog = false
          this.$parent.getProjectList()
          this.$message.success(res.message)
        }
        setTimeout(() => {
          this.submitLoading = false
          this.draftLoading = false
        }, 500)
      },

      async limitInput(val, item, keyword) {
        if (!item.limit) return
        let res = await this.$http.getContentNumber({ keyword })
        if (res.status == 200) {
          item.textNum = res.data
          if (res.data > item.limit) {
            item.warning = true
          } else {
            item.warning = false
          }
        }
      },

      // 上传图片
      uploadIMG(params, type) {
        var formData = new FormData()
        formData.append('file', params.file)
        let loadType = { 1: 'loadingApply', 2: 'loadingManage', 3: 'loadingMain', 4: 'loadingFile' }
        let pictureType = { 1: 'detail_picture', 2: 'project_manager_photo', 3: 'main_picture', 4: 'project_reports_or_testimonials' }
        this[loadType[type]] = true
        this.$http.uploadImage(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign[pictureType[type]] = res.data[0]
          }
          setTimeout(() => { this[loadType[type]] = false }, 1000)
        })
      },

      // 上传文件
      uploadFileProject(params) {
        var formData = new FormData()
        formData.append('file', params.file)
        this.loadingFile = true
        this.$http.uploadFileProject(formData).then((res) => {
          if (res.status == 200) {
            this.formLabelAlign.project_reports_or_testimonials = res.data

            this.fileType = this.formLabelAlign.project_reports_or_testimonials
            let strIndex = this.fileType.lastIndexOf('.');
            if (strIndex === -1) { } else { this.fileType = this.fileType.substring(strIndex + 1) }

            this.fileName = this.formLabelAlign.project_reports_or_testimonials
            let nameIndex = this.fileName.lastIndexOf('/');
            if (nameIndex === -1) { } else { this.fileName = this.fileName.substring(nameIndex + 1) }

          }
          setTimeout(() => { this.loadingFile = false }, 1000)
        })
      },

      closeDialog() {
        if (this.isDialogClose) return false
        this.$confirm(this.$t('Posts.488@close_the_pop_up_box'), 'Tips', {
          distinguishCancelAndClose: true,
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.clearFormLabelAlign()
        }).catch(() => {
          this.requestDialog = true
        })
      },

      clearFormLabelAlign() {
        Object.keys(this.formLabelAlign).forEach((key) => {
          if (Array.isArray(this.formLabelAlign[key])) {
            this.formLabelAlign[key] = []
          } else {
            this.formLabelAlign[key] = ''
          }
        })
        this.formLabelAlign.is_has_project = 1
        this.formLabelAlign.is_other_jci = 1
        this.formLabelAlign.is_used_jci_framework = 1
        this.summary_config.warning = false
        this.summary_config.textNum = 0
        this.SDG_config.warning = false
        this.SDG_config.textNum = 0
        this.need_config.warning = false
        this.need_config.textNum = 0
        this.objective_config.warning = false
        this.achievement_config.warning = false
        this.positive_impact_config.warning = false
        this.checked = false
        this.agree = false
      },


      // 新添加
      // 上传
      imgUpload(params, type) {
        if (type == 1) {
          if (this.formLabelAlign.upload_photos_or_videos.length >= 5) return this.$message.warning('No more than 5')
          this.loadingImg1 = true;
        } else {
          this.loadingImg2 = true;
        }
        var formData = new FormData()
        formData.append('file', params.file)
        this.$http.uploadImage(formData).then((res) => {
          this.loadingImg1 = false;
          this.loadingImg2 = false;
          if (res.status == 200) {
            if (type == 1) {
              this.formLabelAlign.upload_photos_or_videos.push({
                type: params.file.type.split('/')[0] == 'image' ? 'image' : 'video',
                url: res.data[0]
              })
            } else {
              this.videoUrl = res.data[0]
            }
          }
        })
      },

      // 删除已上传的图片
      unshiftMedia(index) {
        this.formLabelAlign.upload_photos_or_videos.splice(index, 1)
      },

      beforeUpload(file) {
        const isVideo = file.type.startsWith('video/');
        if (!isVideo) {
          this.$message.warning('Only allow uploading video files!');
          return false; // 阻止文件上传
        }
        return true; // 允许文件上传
      },

      beforeUploadImage(file) {
        const isJPGOrPNG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
        if (!isJPGOrPNG) {
          this.$message.warning('Please upload the correct image format!');
          return false;
        }
        return isJPGOrPNG;
      },

      //限制数字
      limitNumber(value, type) {
        let textType = { 1: 'number_beneficiaries', 2: 'number_volunteers_engaged', 3: 'total_hours_volunteers', 4: 'total_project_cost', 5: 'sponsorship_secured' }
        if (type == 1 || type == 2) {
          this.formLabelAlign[textType[type]] = value.replace(/[^0-9]/g, "");
        } else {
          this.formLabelAlign[textType[type]] = value.replace(/[^0-9.]/g, "");
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .el-image-viewer__wrapper {
    z-index: 999999 !important;
  }

  ::v-deep .project_dialog {
    margin-top: 10px !important;

    .goal_select {
      display: flex;
      flex-direction: column;

      .el-radio {
        display: flex;
        align-items: center;
        line-height: 26px;
      }
    }

    .goal_multi {
      display: flex;
      flex-direction: column;

      .el-checkbox {
        line-height: 26px;
      }
    }

    .specify_text {
      .el-input__inner {
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid #999;
        height: 26px;
        width: 200px;
      }
    }

    .upload_box {
      position: relative;
      width: 250px;
      height: 90px;

      .text_upload {
        position: absolute;
        bottom: -5px;
        left: 96px;
      }
    }

    .file_url {
      position: absolute;
      bottom: -10px;
      left: 96px;

      .close_img {
        position: absolute;
        width: 20px;
        top: -8px;
        right: -6px;
        cursor: pointer;
      }
    }

    .el-form-item {
      margin-bottom: 30px;
    }

    .all_image {

      .main_img,
      video {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .common_item {
      position: relative;
      margin-right: 15px;

      .close_img {
        width: 20px;
        height: 20px;
        position: absolute;
        right: -5px;
        top: -5px;
        cursor: pointer;
      }

      video {
        width: 90px;
        height: 90px;
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }

  .propose_project {
    position: relative;

    .course_box {
      padding: 28px 0 0;
      border-top: 1px solid #f1f1f1;
      height: 75vh;
      overflow-y: auto;
    }

    .confirm_box {
      padding-top: 20px;
      border-bottom: 1px solid #f1f1f1;
    }

    /deep/ .el-form-item__label {
      line-height: 20px;
      word-break: normal;
    }

    /deep/ .el-form--label-top .el-form-item__label {
      padding: 0
    }

    /* /deep/ .el-form-item__label::before, */
    .star {
      position: relative;
    }

    .star::before {
      display: inline-block;
      position: absolute;
      content: '*';
      color: red;
      top: -2px;
      left: -8px;
    }

    .avatar-uploader {
      width: 90px;
      height: 90px;
      overflow: hidden;

      img {
        width: 90px;
        height: 90px;
      }
    }

    input[aria-hidden=true] {
      display: none !important;
    }

    ::v-deep .file_form {
      .el-form-item__content {
        line-height: 28px !important;
      }
    }
  }

  @media (max-width: 600px) {
    ::v-deep .project_dialog {
      width: 100% !important;
      height: 100vh;
      margin-top: 0 !important;
      margin: 0 auto;
      overflow-y: auto;
    }

    .picture_flex {
      flex-direction: column;
    }

    .text_flex {
      flex-direction: column;
    }

    .picture_left {
      margin-left: 0 !important;
    }

    .el-message-box {
      width: 100px !important;
    }

    ::v-deep .el-form-item__label {
      font-size: 12px;
    }

    .el-form-item {
      margin-bottom: 10px;
    }

    .demo_item {
      ::v-deep .el-form-item__content {
        line-height: 20px;
      }

      p {
        word-break: keep-all;
      }
    }

    .text12 {
      font-size: 12px;
      line-height: 20px;
      margin-top: 3px !important;
    }

    ::v-deep .el-checkbox__input {
      vertical-align: top;
    }

    ::v-deep .el-checkbox__label {
      font-size: 12px;
      white-space: normal;
      word-break: break-word;
      line-height: 18px !important;
      vertical-align: top;
      margin-top: -1px;
    }

    ::v-deep .el-radio {
      align-items: flex-start !important;
      margin-bottom: 15px;
    }

    ::v-deep .el-radio__input {
      vertical-align: top;
    }

    ::v-deep .el-radio__label {
      font-size: 12px;
      white-space: normal;
      word-break: break-word;
      line-height: 18px !important;
      vertical-align: top;
      margin-top: -1px;
    }

    .common_item {
      margin-bottom: 10px;
    }

    .upload_box_file {
      margin-top: 10px;
    }

    .file_url {
      span {
        font-size: 12px;
      }
    }

    ::v-deep .file_form {
      .el-form-item__content {
        line-height: 24px !important;
      }
    }
  }
</style>

<style lang="scss">
  @media (max-width: 600px) {
    .el-message-box {
      width: 100% !important;
    }
  }
</style>